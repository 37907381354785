/* OrderStatus Select, Vue Component */
<template>
    <v-chip :color="status.replace(' ', '') + ' v-chip-light-bg'" outlined small>{{ status }}</v-chip>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: true
        }
    }
}
</script>