/* Order List, Vue Component */
<template>
  <v-card id="order-list">
    <v-card-title>Quotes</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
      </div>
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5 flex-wrap">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mb-4 mr-4"
        ></v-text-field>

         <v-text-field v-model="search.Reference"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Reference"
          class="mb-4 mr-4"
        ></v-text-field>

        <v-text-field v-model="search.MemberId_Reference"
          single-line
          dense
          outlined
          hide-details
          placeholder="Member Reference"
          class="mb-4 mr-4"
        ></v-text-field>

        <v-text-field v-model="search.MemberId_FullName"
          single-line
          dense
          outlined
          hide-details
          placeholder="Member Name"
          class="mb-4 mr-4"
        ></v-text-field>

        <v-select
          v-model="filter.StatusIds"
          :items="filterItems.StatusId"
          single-line
          outlined
          dense
          hide-details
          :clearable="false"
          multiple
          placeholder="Filter Status"
          class="mb-4 mr-4"
        >
        <template v-slot:selection="data">
          <order-status-chip :status="data.item.text"></order-status-chip>
        </template>
        </v-select>


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.QuotePrice`]="{item}">
      {{ Display.Money(item.ProductId_Price) }}
    </template>

    <template #[`item.Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: item.Id } }">
        {{ item.Reference }}
      </router-link>
    </template>

    <template #[`item.MemberId_Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'member-view', params: { id: item.MemberId } }">
        {{ item.MemberId_Reference }}
      </router-link>
    </template>

    <template #[`item.StatusId_Name`]="{item}">
      <order-status-chip :status="item.StatusId_Name"></order-status-chip>
    </template>

    <template #[`item.ProductId_Name`]="{item}">
      <div v-if="item.BespokePrice">
        <v-chip title="Custom Shipment" small color="info" outlined class="v-chip-light-bg">
           <v-icon class="me-1" size="16">{{ icons.mdiCog }}</v-icon>
           {{ item.CustomLength }}x{{ item.CustomWidth }}x{{ item.CustomHeight }} mm
         </v-chip>
      </div>
      <div v-else-if="item.ProductId_Name">
        {{ item.ProductId_Code}} - {{ item.ProductId_Name}}
      </div>
      <div v-else class="caption d-flex">
         <v-chip title="Quote" small color="warning" outlined class="v-chip-light-bg">
           <v-icon class="me-1" size="16">{{ icons.mdiBookEditOutline }}</v-icon>
           {{ item.CustomLength }}x{{ item.CustomWidth }}x{{ item.CustomHeight }} mm
         </v-chip>
      </div>
    </template>

    <template #[`item.Updated`]="{item}">
      {{ Display.DateTime(item.Updated) }}
    </template>

		<!-- <template #[`item.ProductId_Price`]="{item}">
      {{ Display.Money(item.ProductId_Price) }}
    </template> -->


    <template #[`item.DeliveryAddressId_Summary`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.DeliveryAddressId_Summary }}</div>
        </template>
        <span>{{ item.DeliveryAddressId_Summary }}</span>
      </v-tooltip>
    </template>

    <template #[`item.MemberId_Email`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.MemberId_Email }}</div>
        </template>
        <span>{{ item.MemberId_Email }}</span>
      </v-tooltip>
    </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <Can I="create" on="Quotes" v-if="item.StatusId_Name == 'Quote Pending'">
          <v-btn small color="success" :to="{ name: 'order-quote', params: { id: item.Id } }">Quote</v-btn>
          </Can>
          <Can I="create" on="Quotes" v-if="item.StatusId_Name == 'Quote'">
          <v-btn small color="secondary" :to="{ name: 'order-quote', params: { id: item.Id } }">Update</v-btn>
          </Can>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'
import { Can } from '@casl/vue'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'

import { ref, watch, computed, reactive } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'

//import OrderStatusSelect from '@/views/orderstatus/OrderStatusSelect'
//import ProductSelect from '@/views/product/ProductSelect'
//import MemberSelect from '@/views/member/MemberSelect'
//import MemberLookup from '@/views/member/MemberLookup'
// import AddressSelect from '@/views/address/AddressSelect'

export default {
  components: {
    Can,
    OrderStatusChip
    //OrderStatusSelect,
		//ProductSelect,
		//ManifestSelect,
		//MemberSelect,
    //MemberLookup,
		// AddressSelect
  },
  setup() {

  // define cols
  const tableColumns = [
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
    { text: 'Reference', value: 'Reference' },
		{ text: 'Status', value: 'StatusId_Name' },
    { text: 'Updated', value: 'Updated' },
		{ text: 'Dimensions', value: 'Dimensions', sortable: false },
		{ text: 'Quoted', value: 'QuotePrice' },
		// { text: 'Product', value: 'ProductId_Name' },
		// { text: 'Price', value: 'ProductId_Price' },
		{ text: 'Member', value: 'MemberId_Reference' },
		{ text: 'Name', value: 'MemberId_FullName' },
		{ text: 'Member Email', value: 'MemberId_Email' },
		// { text: 'Delivery', value: 'DeliveryAddressId_SummaryExtended' },
		// { text: 'Ret. Ref.', value: 'RetailerReference' },
		{ text: 'Items', value: 'Item_Count' },
		// { text: 'Retailer Tracking Reference', value: 'RetailerTrackingReference' },
    { text: 'Outstanding Invoice', value: 'Invoice_UnpaidCount' },
  ];

  // states needed for filter
  const filterOrderStatuses = computed(() => store.getters["app/OrderStatusQuotes"]);
  const orderStatusesByName = computed(() => store.getters["app/OrderStatusByName"]);
  const orderStatusesById = computed(() => store.state.app.OrderStatusById);

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    StatusIds: []
  });
  const options = ref({
    sortBy: ['Updated'],
    sortDesc: [true],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Orders || []);
  const totalItems = computed(() => store.state.app.OrdersTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = computed(() => {
    if (filterOrderStatuses.value) {

      filter.value.StatusIds = [];

      if (filter.value.StatusIds.length == 0) {
        if (orderStatusesByName.value["Quote Pending"]) {
          filter.value.StatusIds.push(orderStatusesByName.value["Quote Pending"]);
        }
        if (orderStatusesByName.value["Quote"]) {
          filter.value.StatusIds.push(orderStatusesByName.value["Quote"]);
        }
      }

      return {
        StatusId: filterOrderStatuses.value.map(x => {
          return {"text": orderStatusesById.value[x], "value": x}
        })
      };
    } else {
      return { StatusId: [] };
    }
  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchOrders', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }


    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
